<template>
  <fragment>
    <div class="layer_body">
      <div class="group_filebtn">
        <input
          id="files"
          ref="files"
          type="file"
          class="inp_file"
          multiple
          files="[]"
          @change="attachFile()"
        />
        <label class="lab_file" for="files"> <span class="ico_account" />파일첨부 </label>
      </div>
      <form ref="fileform">
        <div ref="fileDragDrop" class="list_fileadd">
          <div v-show="isEmpty" class="empty_txt">여러개의 파일을 마우스로 끌어놓으세요.</div>
          <div
            v-for="(file, key) in fileList"
            v-show="!isEmpty"
            :key="key"
            class="group_addition drop-files"
          >
            <a
              href="javascript:void(0);"
              class="link_down"
              title="파일 다운로드"
              @click.prevent="fileDownload(file)"
              >{{ file.name }}</a
            ><!-- href에 파일 경로 삽입 -->
            <a href="javascript:void(0);" class="link_del">
              <span class="ico_account" @click.prevent="removeFile(key, file)">첨부파일 삭제</span>
            </a>
          </div>
        </div>
      </form>
    </div>
    <div class="layer_foot">
      <div class="wrap_btn">
        <button type="button" class="btn_medium btn_primary" @click.prevent="uploadFile">
          확인
        </button>
      </div>
      <!-- <slot></slot> X(창닫기) -->
    </div>
  </fragment>
</template>

<script>
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { Fragment } from "vue-fragment";
import axios from "axios";

export default {
  name: "CommPopFileUpload",
  components: { Fragment },
  mixins: [CommLayerMixin],
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dragAndDropCapable: false,
    };
  },
  computed: {
    isEmpty() {
      if (this.fileList && this.fileList.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    /*
  Determine if drag and drop functionality is capable in the browser
  */
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    let my = this;
    /*
  If drag and drop capable, then we continue to bind events to our elements.
  */
    if (this.dragAndDropCapable) {
      /*
    Listen to all of the drag events and bind an event listener to each
    for the files.
  */
      ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach(
        function (evt) {
          /*
    For each event add an event listener that prevents the default action
    (opening the file in the browser) and stop the propagation of the event (so
    no other elements open the file in the browser)
    */
          my.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(),
            false,
          );
        },
      );

      /*
    Add an event listener for drop to the form
  */
      this.$refs.fileform.addEventListener(
        "drop",
        function (e) {
          /*
    Capture the files from the drop event and add them to our local files
    array.
    */
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            this.fileList.push(e.dataTransfer.files[i]);
          }
          my.$refs.fileDragDrop.classList.remove("drag_on");
        }.bind(this),
      );

      /*
    Add an event listener for drag to the form
  */
      this.$refs.fileform.addEventListener(
        "dragover",
        function (e) {
          my.$refs.fileDragDrop.classList.add("drag_on");
        }.bind(),
      );
    }
  },
  methods: {
    uploadFile() {
      let attachFileList = [];
      let formData = new FormData();
      for (var i = 0; i < this.fileList.length; i++) {
        let file = this.fileList[i];
        if (file.flag) {
          attachFileList.push(file);
        } else {
          formData.append("fileList[" + i + "]", file);
        }
      }
      axios
        .post("/api/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          res.data.fileList.forEach((file) => {
            file.flag = "1";
            attachFileList.push(file);
          });
          this.fileList = [];
          this._okPopup(attachFileList);
          this.$parent.$destroy();
        })
        .catch((error) => {
          this.output = error;
          console.log(error.response);
          this.$parent.$destroy();
        });
    },
    attachFile() {
      let attachFiles = this.$refs.files.files;
      for (var i = 0; i < attachFiles.length; i++) {
        this.fileList.push(attachFiles[i]);
      }
    },
    fileDownload(file) {
      axios.post("/api/getTenth2DownloadUrl", { filePath: file.url }).then((res) => {
        let fileUrl = res.data;
        let iframe = document.getElementById("hiddenDownloader");
        if (iframe == null) {
          iframe = document.createElement("iframe");
          iframe.id = "hiddenDownloader";
          iframe.style.visibility = "hidden";
          document.body.appendChild(iframe);
        }
        iframe.src = fileUrl;
      });
    },
    removeFile(key, file) {
      if (file.flag === "1") {
        axios
          .get("/api/deleteFile", {
            params: {
              filePath: file.url,
            },
          })
          .then((res) => {
            this.fileList.splice(key, 1);
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        this.fileList.splice(key, 1);
      }
    },
    beforeClose() {
      this.$parent.$destroy();
      this._closeLayer();
    },
    determineDragAndDropCapable() {
      /*
    Create a test element to see if certain events
    are present that let us do drag and drop.
  */
      var div = document.createElement("div");

      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
  },
};
</script>
